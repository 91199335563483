<template>
    <div>
        <div class="leftpan__titlewrap">
            <img
                class="leftpan__logo"
                :src="`/img/platforms/${channelDetails.icon_name}.svg`">
            <div class="leftpan__title">
                {{ fbToMeta(channelDetails.display_name) }}
            </div>
        </div>
        <div class="leftpan__campaigns">
            <div v-if="campaigns.length">
                <campaign
                    v-for="campaign in campaigns"
                    :key="campaign.external_id"
                    :ad-account-id="adAccountId"
                    :campaign-id="campaign.external_id"
                    :ad-sets="campaign.ad_sets"
                    :channel="channel"
                    :placement="placement"
                    :ad-status="adStatus"
                    :dealer-id="dealerId"
                    :agency-id="agencyId"
                    :ad-preview-key="adPreviewKey" />
            </div>

            <div v-else>
                <h3
                    class="text-center channel-message-header">
                    There is no active ad for {{ fbToMeta(channelDetails.display_name) }}!
                </h3>
            </div>
        </div>
    </div>
</template>

<script>
import Campaign from '@/components/playbook-management/ad-preview/Campaign';
import { getChannelByPlatformName } from '@/components/onboarding/channel-setup/channels';
import { fbToMeta } from '@/helpers/fbToMeta';
export default {
    components: {
        Campaign
    },
    props: {
        adAccountId: {
            type: String,
            required: true
        },
        campaigns: {
            type: Array,
            required: true
        },
        channel: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            required: true
        },
        adStatus: {
            type: String,
            required: true
        },
        dealerId: {
            type: Number,
            required: true
        },
        agencyId: {
            type: Number,
            required: true
        },
        adPreviewKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            fbToMeta
        };
    },
    computed: {
        channelDetails() {
            return getChannelByPlatformName(this.channel);
        }
    }
};
</script>

<style lang="scss">
.channel-message-header {
    padding: 1.5rem;
    color: $gray;
}
</style>
